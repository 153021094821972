
/* import Compressor from 'compressorjs';  */
import { validators } from '@waves/waves-transactions'
import Web3 from 'web3';
export default {
  data: function() {
    return {
      web3: new Web3()
    };
  },
  filters: {
    shortTxAddr: function(str){
      let getLeft = str.substring(0, 5)
      let getRight = str.substring(str.length-5)
      return getLeft+"..."+getRight
    },
    feeToDecimal: function(amount){
      return amount / Math.pow(10, 6)
    },
    numberWithSpaces: function (x) {
      let resultat = ""
      if(x){
        if(x.toString().includes(".")){
          var parts = x.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          resultat = parts.join(".");
        }else{
          resultat = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
         
        }
      }
      
      return resultat == "" ? 0 : resultat 
    },
  },
  methods: {
    getWavesCurrentBlock: async function(){
      let current = await fetch(`${this.nodeURL}/blocks/height`)
      current = await current.json()
      this.currentBlock = current.height
      return current.height
    },
    isWavesAddress: function(addr){
      return validators.isValidAddress(addr)
    },
    isEthAddress: function(addr){
      return this.web3.utils.isAddress(addr)
    },
    copyText: async function(e, text){
      e.preventDefault()
      let newText

    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        
      }
      else {
        e.clipboardData.getData('text/plain');
      }
      console.log(newText)
      e.target.parentNode.querySelector(".copied").classList.add("show")
          e.target.parentNode.querySelector(".copied").innerHTML = "copied!";
          setTimeout(function() {
            e.target.parentNode.querySelector(".copied").innerHTML = "";
            e.target.parentNode.querySelector(".copied").classList.remove("show")
          }, 2000);
        
      } catch (err) {
        console.log(err)
        console.log("Oops, unable to copy");
        e.target.parentNode.querySelector(".copied").innerHTML = "";
        e.target.parentNode.querySelector(".copied").classList.remove("show")
      }
      
    }
  }
};