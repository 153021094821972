<template>
  <div id="app">
    <div id="gatestatus">
      <div id="waves-status">
        <div class="mr-2">WAVES:</div>
        <div class="dappstatus paused" v-if="!$store.state.gatewayStatus.dappStatus" v-b-tooltip.hover.bottom :title="$store.state.gatewayStatus.pausedReason"><i class="fa-solid fa-ban"></i></div>
        <div class="dappstatus" v-else><i class="fa-solid fa-check"></i></div>
      </div>
      <div id="eth-status">
        <div class="mr-2">ETH:</div>
        <div class="dappstatus paused" v-if="!$store.state.gatewayStatus.dappStatusETH" v-b-tooltip.hover.bottom :title="$store.state.gatewayStatus.pausedReasonETH"><i class="fa-solid fa-ban"></i></div>
        <div class="dappstatus" v-else><i class="fa-solid fa-check"></i></div>
      </div>
    </div>
    <div class="col-12 mt-3 mb-3 title d-flex justify-content-center align-items-end">
      <img alt="Vue logo" src="./assets/images/logo.svg" class="main_logo">
      <h1>Art </h1>
      <span> GATEWAY</span>
    </div>
    <Home msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import Home from './components/Home.vue'

export default {
  name: 'App',
  components: {
    Home
  },
  data(){
    return {}
  },
  async created(){
    await this.$store.dispatch("getConfirmationsBlocks")
    await this.$store.dispatch("getABI")
    await this.$store.dispatch("checkDappStatus")
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
body *{
  font-family: 'Open Sans', sans-serif;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
}
.main_logo{
  width: 120px;
}
#gatestatus{
  position: absolute;
  top: 7px;
  right: 12px;
  display: flex;
  font-size: 12px;
  #waves-status{
    display: flex;
    margin-right: 10px;
  }
  #eth-status{
    display: flex;
  }
  .dappstatus{
    background-color: #57c75a;
    color: white;
    padding: 0 3px;
    display: inline-block;
    border-radius: 3px;
    cursor: pointer;
    &.paused{
      background-color: red;
      color: white;
    }
  }
  .fa-solid, .fas {
    font-family: "Font Awesome 6 Free"!important;
  }
}
</style>
