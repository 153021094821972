<template>
  <div class="text-center d-flex flex-sm-row flex-column justify-content-center">
    <span class="mr-2"><a :href="$store.state.config.wavesexplorerURL+'/tx/'+tx.txid" target="_blank">Transfer</a> to: <a :href="$store.state.config.etherscanURL+'/address/'+tx.recipient" target="_blank">{{tx.recipient  | shortTxAddr }}</a></span> 
    <span class="mr-2">NFT ID: <a :href="$store.state.config.wavesexplorerURL+'/assets/'+tx.nft_id" target="_blank">{{tx.nft_name}}</a></span> 
    <span>Status: <span v-if="blockLeft>0">{{ blockLeft }} confirmations left</span><span v-else>Finalizing...</span></span>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js';
export default {
  props: ["tx", "currentBlock", "index"],
  mixins: [globalMixin],
  data(){
    return {
      blockLeft: this.$store.state.config.confirmations_waves_blocks,
      confirmation: this.$store.state.config.confirmations_waves_blocks
    }
  },
  watch:{
    currentBlock : function(newVal){
      console.log("current block changed to "+newVal)
      this.calculateBlockLeft(newVal)
    },
    blockLeft: function(newVal){
      if(newVal == 0) {
        this.$emit("removePending", this.index)
      }
    }
  },
  async created(){
    this.calculateBlockLeft(this.currentBlock)
  },
  filters: {

  },
  methods: {
    calculateBlockLeft: function(newVal){
      console.log("this.tx.block")
      console.log(this.tx.block)
      console.log("newVal")
      console.log(newVal)
      let gap =  newVal - this.tx.block
      console.log("gap")
      console.log(gap)
      this.blockLeft = Math.abs(gap) > this.confirmation ? 0 : this.confirmation - Math.abs(gap)
      console.log("this.blockLeft")
      console.log(this.blockLeft)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>