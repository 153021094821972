<template>
  <div class="gateway">

    <div class="container-fluid">
      <div class="row">


        <ul class="nav nav-tabs" id="onglets" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="fromWaves" data-bs-toggle="tab" data-bs-target="#tab-content-1" type="button" role="tab" aria-controls="tab-content-1" aria-selected="false" @click="clearChildResult++">Send to ETH</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="toWaves" data-bs-toggle="tab" data-bs-target="#tab-content-2" type="button" role="tab" aria-controls="tab-content-2" aria-selected="false" @click="clearChildResult++">Send to WAVES</button>
          </li>
        </ul>

        <div id="content" class="tab-content col-12">
            <To-Eth :clear="clearChildResult"/>
            <To-Waves :clear="clearChildResult"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ToWaves from './ToWaves.vue'
import ToEth from './ToEth.vue'

export default {
  name: 'Gateway',
  props: {
    msg: String
  },
  components: {
    'To-Waves': ToWaves,
    'To-Eth': ToEth
  },
  data(){
    return {
      clearChildResult: 0
    }
  },
  
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@media(max-width: 400px){
  .addressForm{
    .form-control{
      padding-left: 15px;
      &.me-3{
        margin-right: 0.5rem!important;
      }
    }
  }
}
#gateway_fee{
  background-color: #faf2ff;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 400;
  strong{
    color: #841e7b;
  }
  .mention{
    color: #9b6196;
  }
}
#balance{
  font-size: 14px;
  color: #a3a3a3;
  letter-spacing: 0.5px;
  display: block;
}
#result{
  background-color: #d1f5d9;
  border: dotted 1px #5dc7a0;
  padding: 10px;
  border-radius: 5px;
  font-weight: 500;
  position: relative;
  
  &.error{
    background-color: #fbd9d9;
    border: dotted 1px #f17575;
    overflow-x: hidden;
  }
  .close{
    cursor: pointer;
    position: absolute;
    top: -3px;
    right: 5px;
    .fa-xmark{
      font-size: 16px;
    }
  }
}
  .mention {
      font-size: 13px;
      margin-top: 8px;
      font-style: italic;
  }
  #signingChoice{
    justify-content: center;
    align-items: center;
    display: flex;
    color: #57137b;
    .wrap_choice{
      background-color: white;
      padding: 15px;
      width: 100%;
      max-width: 380px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .wrap_btn{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .btn{
        /* padding: 10px 25px;
        background-color: #ca26c0;
        color: #fff;
        transition: all .4s ease;
        &:hover{
          background-color: #57137b;
        } */
          width: 90%;
          transition: all 0.4s ease;
          padding-left: 20px;
          padding-right: 20px;
          border-radius: 50px;
          background-color: white;
          border: solid 1px #9A0E88;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 147%;
          text-align: center;
          letter-spacing: -0.015em;
          color: #131111;
          padding-top: 3px;
          padding-bottom: 3px;
          align-items: center;
          &:hover{
            color: white;
            background-color: #131111;
            border-color: #131111;
            &.use_ledger svg, &.use_keeper svg{
              filter: brightness(0) invert(1);
            }
          }
      }
    }
  }

.fa-copy{
  cursor: pointer;
  transition: opacity .4s ease;
  margin-left: 5px;
  &:hover{
    opacity: 0.6;
  }
}

.copied{
  opacity: 0;
  pointer-events: none;
  transition: opacity .4s ease;
  position: absolute;
  z-index: 5;
  top: -25px;
  right: -20px;
  background-color: white;
  padding: 3px 5px;
  &.show{
    opacity: 1;
  }
}
.form-select, .form-control, .btn-primary{
  border-radius: 50px;
  padding-left: 25px;
}
.btn-primary{
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ba53f0;
    border-color: #ba53f0;
}
.form-control:focus,
.form-select:focus,
.btn.active:focus,
.btn:focus{
  border-color: #9c9c9c;
  box-shadow: none;
}

.btn-primary,.btn:focus{
  background-color: #270838;
  border: 0px;
  transition: all .4s ease;
  &:hover{
    background-color: #74279e;
  }
}
.send_btn{
  pointer-events: none;
  background-color: rgb(204, 204, 204);
  cursor: none;
  border: 0;
  &.active{
    background-color: #ba53f0;
    pointer-events: all;
    cursor: pointer;
    &:hover{
      background-color: #270838;
    }
  }
}
  .bottom{
    position: fixed;
    bottom: 0%;
    left: 0;
    width: 100%;
    background-color: #f3f3f3;
    color: white;
    padding: 8px;
    z-index: 70;
    .dapp_address{
      color: #7e7e7e;
      letter-spacing: 0.5px;
      font-size: 13px;
    }
  }

.title{
  padding-bottom: 15px;
  h1{
    font-weight: 300;
    font-size: 28px;
    text-transform: uppercase;
    margin-bottom: 0;
    line-height: 26px;
    padding-left: 7px;
  }
  span{
    font-style: italic;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    margin-left: 8px;
  }

}
h4{
  font-weight: 600;
  font-size: 22px;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  .arrow_loading{
    position: absolute;
    right: 0;
    font-size: 18px;
    top: 3px;
    cursor: pointer;
    .loading{
      animation: rotation 2s infinite linear;
    }
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
h2{
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
  .top{
    margin-top: 5px;
    .connectedWith{
      
    }
  }
  .addressForm{
    margin-top: 20px;
  }
#onglets{
  display: flex;
  justify-content: center;
  .nav-item{
    margin-right: 15px;
    &:last-child{
      margin-right: 0;
    }
    .nav-link{
      padding: 10px 20px;
      border: solid 1px #ccc;
      border-radius: 10px 10px 0 0;
      border-bottom: 0px;
      z-index: 1;
      color: black;
      cursor: pointer;
      }
    }
    h2{
      margin-bottom: 0;
      font-weight: 600;
    }
  
}
#content{
  width: 100%;
  max-width: 1000px;
  margin: auto;
  padding-top: 20px;
  z-index: 0;
  position: relative;
  padding-bottom: 60px;
  .tab-content{
    background-color: white;
  }
}
.wrap_items{
  padding-top: 20px;
  
    .item{
      &.selected, &:hover{
        .wrap_item{
          background-color: #521c6e;
          color: white;
          position: relative;
          &:before{
            content: "\f058";
            display: block;
            position: absolute;
            z-index: 5;
            font-family: "Font Awesome 5 Free"; 
            font-weight: 900; 
            right: 10px;
            top: 5px;
            color: white;
          }
          .attributes{
            color: white;
          }
        }
        .wrap_item.isLocked{
          &:before{
            display: none;
          }
          .locked{
            .fa-lock{
              color: white;
            }
          }
        }
      }
      .wrap_item{
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        border: solid 1px #e3e3e3;
        border-radius: 10px;
        cursor: pointer;
        transition: all .4s ease;

        .attributes{
          position: absolute;
          right: 8px;
          bottom: 5px;
          text-align: right;
          font-size: 13px;
          width: 100%;
          color: #999;
        }
        .thumb{
          width: 80px;
          min-width: 80px;
          height: 80px;
          object-fit: cover;
          margin-right: 10px;
          border-radius: 10px 0 0 10px;
        } 

        h3{
          font-size: 16px;
          text-align: left;
        }
      }
    }
  
}
#pending{
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 10px;
  font-size: 14px;
  height: auto;
  max-height: 150px;
  overflow-y: auto;
  h5{
    font-size: 15px;
    text-transform: uppercase;
    color: #5e5e5e;
  }
  &.closed{
    height: 0px;
  }
  ul{
    padding: 0;
    li{
      padding: 0 5px;
      list-style: none;
      a{
        color: #BA53F0;
      }
    }
  }
}

</style>
